import "./button.scss"

import React, { MouseEvent } from "react"

import { classList } from "../../utils/utils"

export enum KIND {
  primary = "primary",
  secondary = "secondary",
}

interface ButtonProps {
  onClick?: (e: MouseEvent) => any
  disabled?: boolean
  kind?: KIND
}

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  disabled = false,
  kind = KIND.secondary,
}) => {
  const btnClasses = classList(
    "btn",
    kind === KIND.primary && "btn--primary",
    disabled && "btn--disabled"
  )

  return (
    <button onClick={onClick} disabled={disabled} className={btnClasses}>
      {children}
    </button>
  )
}

export default Button
