import { graphql, useStaticQuery } from "gatsby"

import { Helmet } from "react-helmet"
import React from "react"

interface SEOProps {
  title?: string
  description?: string
}

const Seo: React.FC<SEOProps> = ({ title, description }) => {
  const { site } = useStaticQuery(query)

  const { defaultTitle, defaultDescription } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
  }

  return (
    <Helmet>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
    </Helmet>
  )
}

export default Seo

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
      }
    }
  }
`
