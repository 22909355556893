import "./layout.scss"
import "@fontsource/rubik"

import React from "react"

export const Layout: React.FC = ({ children }) => {
  return <div>{children}</div>
}

interface RowProps {
  style?: React.CSSProperties
  reverse?: boolean
  center?: boolean
  classNames?: string
}

export const Row: React.FC<RowProps> = ({ children, style, classNames }) => {
  return (
    <div className={`row ${classNames ? classNames : ""}`} style={style}>
      {children}
    </div>
  )
}

interface ColProps {
  span: number
  md?: number
  classNames?: string
  style?: React.CSSProperties
}
export const Col: React.FC<ColProps> = ({
  children,
  span = 12,
  md,
  classNames,
  style,
}) => {
  let smClass = `span-${span} `
  let mdClass = md ? `span-${md}--md` : ""
  return (
    <div
      className={
        `col ` + mdClass + ` span-${span} ${classNames ? classNames : ""}`
      }
      style={style}
    >
      {children}
    </div>
  )
}
